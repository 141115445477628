import { toast } from "react-toastify"
import { DislocationType, getDislocationType, getRouteDetails } from "../../../../utils/geolocation.utils"
import { TariffInfoType, TypeOfCompany, getPriceFormatted, getPriceFromTariffInfoObject } from "../../../../utils/pricing.utils"
import { statusListOfFinal } from "../../../../utils/style.utils"
import { POI } from "../../poi-component/poiConstants"
import { extractBaseCurrency, getRentTariffInfo, getTariffInfo } from "../utils"
import { hasRentTariffFields, hasTariffFields } from "../../../../utils/transport.utils"
import _ from "lodash"
import { ExtraType, SelectedExtraType } from "../components/rent/OptionsSelect"
import { TransportType } from "./transportTypes"
import moment from "moment"

export const transportFieldsTariff = new Set([
  'effectiveDateString',
  'type',
  'originCoords',
  'destinationCoords',
  'intermediaryPoints',
  'completeEstimateDateString',
  'supplier',
  'idCompanyClient',
  'idTariffService',
  'idTariffType',
  'currencyString',
  'paymentTypeString',
  'duration',
  'distance',
  'tariffString',
  'commission',
  'taxBreakdown',
  'commissionBreakdown',
  // rent fields
  'extraOptions',
  'vehicleClass',
  'dislocationType',
  'insurance',
  'insuranceType',
  'discount',
])

export const enum BlockedStatus {
  NotBlocked = 'notBlocked',
  TariffBlocked = 'tariffBlocked',
  CommissionBlocked = 'commissionBlocked',
  BothBlocked = 'bothBlocked',
}

export const getBlockedStateFromTransport = ({
  blockTariff,
  blockCommission,
}: {
  blockTariff: boolean
  blockCommission: boolean
}): BlockedStatus => {
  if (blockTariff && blockCommission) {
    return BlockedStatus.BothBlocked
  } else if (blockTariff) {
    return BlockedStatus.TariffBlocked
  } else if (blockCommission) {
    return BlockedStatus.CommissionBlocked
  }
  return BlockedStatus.NotBlocked
}

export type RouteDetailsType = {
  duration?: number
  distance?: number
  estimatedWait?: number
  completeEstimateDate?: string
}

export const updateTariffInfo = async ({
  transport,
  firstLoadingTransport,
  voucher,
  intermPOIList,
  tariffInfo,
  setTariffDataWithNewInfo,
  businessProfileId,
  overwrite = false,
  routeDetailsInfo = {},
  updateBaseCurrency = false,
  setIsFormLoading
}: {
  transport: TransportType
  firstLoadingTransport: boolean
  voucher: string
  intermPOIList: POI[]
  tariffInfo: TariffInfoType
  setTariffDataWithNewInfo: Function
  businessProfileId: number
  overwrite?: boolean
  routeDetailsInfo?: RouteDetailsType
  updateBaseCurrency?: boolean
  setIsFormLoading: Function
}): Promise<boolean> => {
  setIsFormLoading(true)
  let futureTransport = { ...transport, ...routeDetailsInfo }
  if (
    !Boolean(transport) ||
    !hasTariffFields({ transport: futureTransport }) ||
    (firstLoadingTransport && !_.isEmpty(futureTransport?.taxBreakdown || {}))
  ) {
    if (updateBaseCurrency && !statusListOfFinal.includes(futureTransport?.requestStatus)) {
      setTariffDataWithNewInfo({
        ...tariffInfo,
        currency: futureTransport?.company?.currency || 'RON',
      })
    }
    setIsFormLoading(false)
    return false
  }
  // if the status is final, we will not recompute the tariff
  if (statusListOfFinal.includes(futureTransport?.requestStatus)) {
    toast.warning('The status is marked as final so the tariff will not recompute.')
    setIsFormLoading(false)
    return false
  }


  const taxBreakdown = tariffInfo?.taxBreakdown || {}
  const commissionBreakdown = tariffInfo?.commissionBreakdown || {}

  let taxBreakdownManual = {}
  Object.entries(taxBreakdown).forEach(([key, info]) => {
    if (info.manuallyEdited) {
      taxBreakdownManual[key] = info
    }
  })

  let commissionBreakdownManual = {}
  Object.entries(commissionBreakdown).forEach(([key, info]) => {
    if (info.manuallyEdited) {
      commissionBreakdownManual[key] = info
    }
  })

  // set new tariff info
  const calculatedTariffInfo: TariffInfoType = await getTariffInfo({
    transport: futureTransport,
    identificationData: {
      voucher,
      businessProfileId
    },
    intermPOIList,
  })

  setIsFormLoading(false)

  if (!calculatedTariffInfo) {
    if (updateBaseCurrency) {
      setTariffDataWithNewInfo({
        ...tariffInfo,
        taxBreakdown: taxBreakdownManual,
        commissionBreakdown: commissionBreakdownManual,
        currency: futureTransport?.company?.currency || 'RON',
      })
    }
    return false
  }
  // delete the tariffDetail, because we don't need it
  delete calculatedTariffInfo.tariffDetail
  delete calculatedTariffInfo.tariffDetails

  let futureTaxBreakdown = {
    ...taxBreakdownManual,
    ...(calculatedTariffInfo?.taxBreakdown || {}),
  }

  let futureCommissionBreakdown = {
    ...commissionBreakdownManual,
    ...(calculatedTariffInfo?.commissionBreakdown || {}),
  }
  // the tariff will always be calculated for a valid request
  if (overwrite) {
    const futureTariffInfo: TariffInfoType = {
      ...calculatedTariffInfo,
      taxBreakdown: futureTaxBreakdown,
      commissionBreakdown: futureCommissionBreakdown
    }
    if (updateBaseCurrency) {
      futureTariffInfo.currency = futureTransport?.company?.currency || 'RON'
    }
    setTariffDataWithNewInfo(futureTariffInfo, routeDetailsInfo)
    return true
  }

  let futureTariffCurrency = extractBaseCurrency({
    transport: futureTransport,
    companyType: TypeOfCompany.Client,
    tariffInfo: calculatedTariffInfo,
  })
  let futureCommissionCurrency = extractBaseCurrency({
    transport: futureTransport,
    companyType: TypeOfCompany.Supplier,
    tariffInfo: calculatedTariffInfo,
  })

  const futureTariffInfo = {
    ...tariffInfo,
    taxBreakdown: futureTaxBreakdown,
    commissionBreakdown: futureCommissionBreakdown,
    currency: futureTariffCurrency,
    commissionCurrency: futureCommissionCurrency,
    transferType: calculatedTariffInfo.transferType,
    originAgency: calculatedTariffInfo.originAgency,
  }
  if (updateBaseCurrency) {
    futureTariffInfo.currency = futureTransport?.company?.currency || 'RON'
  }
  setTariffDataWithNewInfo(futureTariffInfo, routeDetailsInfo)
  return true
}

export type RentPlanDataType = {
  transport_request_id?: number,
  currency: string,
  plan_id: number,
  vehicle_class_id: number,
  vehicle_class?: any,
  start_date: string,
  end_date: string,
  insurance_id: number,
  liability_type?: string[],
  insurance?: any,
  extra_costs: SelectedExtraType[],
  discount: number,
  dislocation_type: DislocationType,
  exchange_rate: number,
  distance: number
}

export enum transferActionEnum {
  REPEAT = 'repeat',
  RETURN = 'return',
  REPEAT_ON_DATES = 'repeatOnDates'
}

export type TransportPricingContextType = {
  action: transferActionEnum | null,
  transferType: string,
  originAgency: string
}

export const convertStringToTransferAction = (actionString: string) => {
  switch (actionString) {
    case 'repeat': return transferActionEnum.REPEAT;
    case 'return': return transferActionEnum.RETURN;
    case 'repeatOnDates': return transferActionEnum.REPEAT_ON_DATES;
    default: return null;
  }
}

export const updateTariffInfoRent = async ({
  transport,
  rentPlanData,
  firstLoadingTransport,
  tariffInfo,
  setTariffDataWithNewInfo,
  overwrite = false,
  routeDetailsInfo = {},
  updateBaseCurrency = false,
  allExtraCosts = [],
  setIsFormLoading,
}: {
  transport: TransportType
  rentPlanData: RentPlanDataType
  firstLoadingTransport: boolean
  tariffInfo: TariffInfoType
  setTariffDataWithNewInfo: Function
  overwrite?: boolean
  routeDetailsInfo?: RouteDetailsType
  updateBaseCurrency?: boolean
  allExtraCosts: ExtraType[]
  setIsFormLoading: Function
}): Promise<boolean> => {
  setIsFormLoading(true)
  let futureTransport = { ...transport, ...routeDetailsInfo }
  if (
    !Boolean(transport) ||
    !hasRentTariffFields({ transport: futureTransport, rentPlanData }) ||
    (firstLoadingTransport && !_.isEmpty(futureTransport?.taxBreakdown || {}))
  ) {
    if (updateBaseCurrency && !statusListOfFinal.includes(futureTransport?.requestStatus)) {
      setTariffDataWithNewInfo({
        ...tariffInfo,
        currency: futureTransport?.company?.currency || 'RON',
      })
    }
    setIsFormLoading(false)
    return false
  }
  // if the status is final, we will not recompute the tariff
  if (statusListOfFinal.includes(futureTransport?.requestStatus)) {
    toast.warning('The status is marked as final so the tariff will not recompute.')
    setIsFormLoading(false)
    return false
  }

  const taxBreakdown = tariffInfo?.taxBreakdown || {}
  const commissionBreakdown = tariffInfo?.commissionBreakdown || {}

  let taxBreakdownManual = {}
  Object.entries(taxBreakdown).forEach(([key, info]) => {
    if (info.manuallyEdited) {
      taxBreakdownManual[key] = info
    }
  })

  let commissionBreakdownManual = {}
  Object.entries(commissionBreakdown).forEach(([key, info]) => {
    if (info.manuallyEdited) {
      commissionBreakdownManual[key] = info
    }
  })

  // set new tariff info
  const convertedFilteredExtraOptions = allExtraCosts.filter(extra_costs => extra_costs.is_applicable === 'per week day').map((extra_cost: ExtraType) => ({ id: extra_cost.id, quantity: 1 }))
  const calculatedTariffInfo: TariffInfoType = await getRentTariffInfo({ rentPlanData, filteredExtraCosts: convertedFilteredExtraOptions })

  setIsFormLoading(false)

  if (!calculatedTariffInfo) {
    if (updateBaseCurrency) {
      setTariffDataWithNewInfo({
        ...tariffInfo,
        taxBreakdown: taxBreakdownManual,
        commissionBreakdown: commissionBreakdownManual,
        currency: futureTransport?.company?.currency || 'RON',
      })
    }
    return false
  }
  // delete the tariffDetail, because we don't need it
  delete calculatedTariffInfo.tariffDetail
  delete calculatedTariffInfo.tariffDetails

  let futureTaxBreakdown = {
    ...taxBreakdownManual,
    ...(calculatedTariffInfo?.taxBreakdown || {}),
  }

  let futureCommissionBreakdown = {
    ...commissionBreakdownManual,
    ...(calculatedTariffInfo?.commissionBreakdown || {}),
  }
  // the tariff will always be calculated for a valid request
  if (overwrite) {
    const futureTariffInfo: TariffInfoType = {
      ...calculatedTariffInfo,
      taxBreakdown: futureTaxBreakdown,
      commissionBreakdown: futureCommissionBreakdown
    }
    if (updateBaseCurrency) {
      futureTariffInfo.currency = futureTransport?.company?.currency || 'RON'
    }
    setTariffDataWithNewInfo(futureTariffInfo, routeDetailsInfo)
    return true
  }

  let futureTariffCurrency = extractBaseCurrency({
    transport: futureTransport,
    companyType: TypeOfCompany.Client,
    tariffInfo: calculatedTariffInfo,
  })
  let futureCommissionCurrency = extractBaseCurrency({
    transport: futureTransport,
    companyType: TypeOfCompany.Supplier,
    tariffInfo: calculatedTariffInfo,
  })

  const futureTariffInfo = {
    ...tariffInfo,
    taxBreakdown: futureTaxBreakdown,
    commissionBreakdown: futureCommissionBreakdown,
    currency: futureTariffCurrency,
    commissionCurrency: futureCommissionCurrency,
    transferType: calculatedTariffInfo.transferType,
    originAgency: calculatedTariffInfo.originAgency,
  }
  if (updateBaseCurrency) {
    futureTariffInfo.currency = futureTransport?.company?.currency || 'RON'
  }
  setTariffDataWithNewInfo(futureTariffInfo, routeDetailsInfo)
  return true
}

// get the new route details from the updated transport object provided and set the transport with the new values
export const updateTransportWithNewRouteInfo = async ({
  transport,
  intermPOIList,
  populatedUpdateTariffCall,
  setTransport,
}: {
  transport: TransportType
  intermPOIList: POI[]
  populatedUpdateTariffCall: Function
  setTransport: Function
}) => {
  const result: any = await getRouteDetails({
    startLocation: transport.originCoords,
    endLocation: transport.destinationCoords,
    waypointsArray: intermPOIList ? intermPOIList.map((poi: POI) => poi.gps) : [],
    departureDateString: transport?.effectiveDate,
  })
  const effectiveDateMoment = moment(transport?.effectiveDate) ?? moment()
  const newCompleteEstimatedDate = effectiveDateMoment.add(parseInt((10 + (result?.duration || 0) / 60).toFixed(0)), 'minutes').format('YYYY-MM-DD HH:mm:ss.SSS')

  const routeDetailsInfo: RouteDetailsType = {
    duration: parseInt((10 + (result?.duration || 0) / 60).toFixed(0)),
    distance: Math.ceil((result?.distance || 0) / 1000),
    completeEstimateDate: transport.tariffServiceName === 'Rent a Car' ? transport.completeEstimateDate : newCompleteEstimatedDate,
  }
  // we check to see if the tariff updated accordingly
  const updateSuccessful: boolean = await populatedUpdateTariffCall({
    overwrite: false,
    routeDetailsInfo: routeDetailsInfo,
  })
  // if the tariff didn't update, then the transport remained unchanged as well
  // we need to update the transport here, because it was not updated in the updateTariffInfo function
  if (!updateSuccessful) {
    setTransport((oldTransport: TransportType) => ({
      ...oldTransport,
      ...routeDetailsInfo,
    }))
  }
}

export const getPriceByTariffInfo = ({
  transport,
  tariffInfo,
  companyType = TypeOfCompany.Client,
  commissionCurrency,
  tariffExchangeRate,
  commissionExchangeRate,
  VAT,
}: {
  transport: TransportType
  tariffInfo: TariffInfoType
  companyType?: TypeOfCompany
  commissionCurrency: string
  tariffExchangeRate: number
  commissionExchangeRate: number
  VAT: number
}) => {
  const chosenCurrency = companyType === 'client' ? (transport?.currency || tariffInfo?.currency) : commissionCurrency
  const baseCurrency = companyType === 'client' ? extractBaseCurrency({
    transport,
    tariffInfo,
    companyType,
  }) : commissionCurrency
  const exchangeRate = companyType === 'client' ? tariffExchangeRate : commissionExchangeRate

  const futurePrice = getPriceFromTariffInfoObject({
    breakdownObject:
      companyType === TypeOfCompany.Client
        ? tariffInfo?.taxBreakdown
        : tariffInfo?.commissionBreakdown,
    baseCurrency,
    chosenCurrency,
    paymentType: companyType === 'client' ? transport?.paymentType : 'CONTRACT',
    exchangeRate,
    VAT,
  })
  return { price: futurePrice, formattedPrice: getPriceFormatted(futurePrice) }
}